<script>
    import classNames from 'classnames'
    import Image from 'components/Image'
    import Icon from 'components/Icon'
    import Title from 'components/Title'
    import ConditionalLink from 'utils/ConditionalLink'

    export let to // string
    export let image // ImageProps
    export let icon // IconProps
    export let title // string | titleProps
    export let subtitle // string | titleProps
    export let content = null // string
    export let center = false // boolean
    export let rounded = false // boolean
    export let shadowed = false // boolean
    export let bordered = false // boolean
    export let imageRight = false // boolean
    export let imageLeft = false // boolean
    export let plain = null // boolean
    export let withPattern = true
    export let imageDirection = 'alternating'

    export let skillLeft = imageDirection == 'left'

    let className
    export { className as class }
</script>

<style global>
    :global(:root) {
        --card-padding: var(--layout-gutter);
    }
    :global(.Card) {
        display: flex;
        flex-direction: column;
        position: relative;
    }
    :global(.Card--withImage) {
        margin: 0;
    }
    :global(.Card) + :global(.Card) {
        margin-top: calc(var(--layout-gutter) * 4);
    }
    :global(.Card--bordered) {
        border: 1px solid var(--color-light-grey);
        padding: var(--layout-gutter);
        padding: var(--card-padding);
    }
    :global(.Card--rounded) {
        border-radius: 2em;
        border-radius: var(--border-radius, 2em);
    }
    :global(.Card--center) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    :global(.Card--plain) {
        border: none;
        background: none;
        border-radius: 0;
        box-shadow: none;
    }
    :global(.Card--shadowed) {
        box-shadow: var(--box-shadow);
        border: 1px solid var(--color-light-grey);
        padding: var(--layout-gutter);
        padding: var(--card-padding);
        background-color: #fff;
    }
    :global(.Card-imageWrapper) {
        flex: 1 0 50%;
        display: block;
        padding: var(--layout-gutter) 0;
        padding: var(--card-padding) 0;
    }
    @media (min-width: 576px) {
        :global(.Card--imageLeft) {
            flex-direction: row;
        }
        :global(.Card--imageRight) {
            flex-direction: row-reverse;
        }
        :global(.Card--withImage) :global(.Card-wrapper) {
            padding: 0 var(--layout-gutter);
            padding: 0 var(--card-padding);
        }

        :global(.Card--imageLeft) :global(.Card-imageWrapper),
        :global(.Card--imageRight) :global(.Card-imageWrapper) {
            padding: 0 var(--layout-gutter);
            padding: 0 var(--card-padding);
        }
    }
    @media (max-width: 767px) {
        :global(.Card) + :global(.Card) {
            margin-top: calc(var(--layout-gutter) * 2);
        }

        :global(.Card-imageWrapper) {
            margin: 25px 0;
        }
    }
    :global(.Card-wrapper) {
        flex: 1 0 50%;
        display: flex;
        flex-direction: column;
    }
    :global(.Card--center) :global(.Card-imageWrapper),
    :global(.Card--center) :global(.Card-wrapper) {
        width: 100%;
    }
    :global(.Card-content) {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
    }
    :global(.Card--center) :global(.Card-imageWrapper) {
        display: block;
        margin: 0 0 1em;
        flex: 1 1 auto;
    }
    :global(.Card--noLeftPadding) {
        padding: 0 var(--layout-gutter) 0 0;
        padding: 0 var(--card-padding) 0 0;
    }
    :global(.Card--leftList) {
        padding: 0 var(--layout-gutter) 0 0;
        padding: 0 var(--card-padding) 0 0;
        font-size: 1em;
    }</style>

<section
    class={classNames('Card', className, {
        'Card--linked': to,
        'Card--plain': plain,
        'Card--center': center,
        'Card--withIcon': icon,
        'Card--withImage': image,
        'Card--imageRight': imageRight,
        'Card--imageLeft': imageLeft,
        'Card--bordered': bordered,
        'Card--rounded': rounded,
        'Card--shadowed': shadowed
    })}>
    {#if image || icon}
        <div class="Card-imageWrapper" class:Card--noLeftPadding={skillLeft}>
            {#if image}
                <Image
                    lazy
                    {withPattern}
                    class="Card-image"
                    {...image}
                    sizes="(max-width: 575px) 100vw, 600px"
                    aspectRatio={3 / 2}
                    } />
            {:else if icon}
                <Icon class="Card-icon" {...icon} type={icon.type || icon} />
            {/if}
        </div>
    {/if}

    <div class="Card-wrapper" class:Card--leftList={skillLeft}>
        <ConditionalLink if={to} linkProps={{ to, cover: true, plain: true }}>
            {#if title || subtitle}
                {#if title}
                    <Title
                        class="Card-title"
                        level={3}
                        asLevel={4}
                        {...title}
                        text={title} />
                {/if}
                {#if subtitle && subtitle.length}
                    <Title
                        class="Card-subtitle"
                        level={4}
                        asLevel={5}
                        {...subtitle}
                        text={subtitle} />
                {/if}
            {/if}
        </ConditionalLink>
        <div class="Card-content">
            <slot>{content}</slot>
        </div>
    </div>
</section>
