<script>
    import Loader from 'components/Loader'
    import classNames from 'classnames'

    export let src = null // string
    export let filename = null // string (supports storyblok image objects)
    export let srcset = null // string
    export let sizes = null // string
    export let alt = null // string
    export let cover = null // boolean
    export let contain = null // boolean
    export let align = null // null | 'center'
    export let caption = null // string
    export let height = null // number
    export let width = null // number
    export let attributes = {} // any
    export let dimension = null // string
    export let dimensions = null // any
    export let withPattern = false // boolean
    export let rounded = false // boolean
    export let circle = false // boolean
    export let index = null // number
    export let shadow = false // boolean
    export let lazy = false // boolean
    export let aspectRatio = null // number
    let className = null // string
    export { className as class }

    src = src || filename

    const attrs = {
        src,
        srcset: srcset || getSrcSet(src, aspectRatio),
        height,
        width,
        sizes: sizes || '100vw',
        ...(attributes || {})
    }
    if (lazy) {
        attrs['data-lazy'] = true
        attrs['data-srcset'] = attrs.srcset
        attrs['data-src'] = attrs.src

        attrs.srcset = ''
        attrs.src = // blank 1x1 placeholder image
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII='
    }

    /** See https://www.storyblok.com/docs/image-service for options */
    function transformUrl(image, option) {
        var imageService = '//img2.storyblok.com/'
        var path = image.replace('//a.storyblok.com', '')
        return imageService + option + path
    }

    function getSrcSet(src, aspectRatio) {
        const exp = /\/([0-9]*?)x([0-9]*?)\//
        const match = exp.exec(src)
        if (!match) {
            return ''
        }

        let [, originalWidth, originalHeight] = match
        const getBaseDimensions = ([width, height], newAspectRatio) => {
            if (!newAspectRatio) {
                return [width, 0] // 0 === Scale height to original aspect ratio
            }

            const originalRatio = originalWidth / originalHeight
            if (originalRatio < newAspectRatio) {
                return [width, Math.ceil(width / newAspectRatio)]
            } else {
                return [Math.ceil(originalHeight * newAspectRatio), height]
            }
        }

        const [baseWidth, baseHeight] = getBaseDimensions(
            [originalWidth, originalHeight],
            aspectRatio
        )
        const scaleFactors = [1, 0.75, 0.5, 0.25]
        return scaleFactors
            .map(scale => [
                Math.ceil(baseWidth * scale),
                Math.ceil(baseHeight * scale)
            ])
            .map(
                ([width, height]) =>
                    transformUrl(src, `${width}x${height}/smart`) + ` ${width}w`
            )
    }

    function lazyLoad(node) {
        if (!node.getAttribute('data-lazy')) {
            return
        }

        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                showLazyImage(node)
                observer.disconnect()
            }
        }, {})
        observer.observe(node)

        return {
            destroy() {
                observer && observer.disconnect()
            }
        }
    }

    function showLazyImage(node) {
        const originalSrc = node.getAttribute('data-src')
        const originalSrcSet = node.getAttribute('data-srcset')

        if (originalSrcSet) {
            node.setAttribute('srcset', originalSrcSet)
        }
        if (originalSrc) {
            node.setAttribute('src', originalSrc)
        }
    }
</script>

<style global>
    :global(.Image) {
        position: relative;
        display: block;
        max-width: 100%;
    }

    :global(img) {
        max-width: 100%;
        height: auto;
    }

    :global(.Image-caption) {
        display: block;
        font-weight: var(--font-weight-light);
        font-size: 16px;
    }

    :global(.Image--rounded) :global(img) {
        border-radius: 1em;
    }

    :global(.Image--shadow) :global(img) {
        box-shadow: var(--box-shadow);
    }

    :global(.Image--circle) :global(img) {
        border-radius: 50% !important;
    }

    :global(.Image--cover) :global(img),
    :global(.Image--contain) :global(img) {
        display: block;
        -o-object-fit: contain;
           object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    :global(.Image--cover) :global(img) {
        -o-object-fit: cover;
           object-fit: cover;
    }

    :global(.Image-pattern) {
        position: absolute;
        z-index: -1;
        left: 50%;
        top: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
    }

    :global(.Image.clipPath-1) :global(.Image-image) {
        -webkit-clip-path: polygon(0% 0, 100% 10%, 90% 90%, 0 100%);
                clip-path: polygon(0% 0, 100% 10%, 90% 90%, 0 100%);
    }

    :global(.Image.clipPath-2) :global(.Image-image) {
        -webkit-clip-path: polygon(10% 10%, 100% 0%, 100% 100%, 0 90%);
                clip-path: polygon(10% 10%, 100% 0%, 100% 100%, 0 90%);
    }

    :global(.Image.clipPath-3) :global(.Image-image) {
        -webkit-clip-path: polygon(5% 0%, 90% 10%, 100% 100%, 0 80%);
                clip-path: polygon(5% 0%, 90% 10%, 100% 100%, 0 80%);
    }

    :global(.Image.clipPath-4) :global(.Image-image) {
        -webkit-clip-path: polygon(5% 10%, 90% 00%, 100% 80%, 10% 100%);
                clip-path: polygon(5% 10%, 90% 00%, 100% 80%, 10% 100%);
    }

    :global(.Image.clipPath-5) :global(.Image-image) {
        -webkit-clip-path: polygon(0% 0%, 90% 10%, 90% 100%, 0% 90%);
                clip-path: polygon(0% 0%, 90% 10%, 90% 100%, 0% 90%);
    }

    :global(.Image.clipPath-6) :global(.Image-image) {
        -webkit-clip-path: polygon(0% 5%, 100% 0%, 90% 100%, 10% 90%);
                clip-path: polygon(0% 5%, 100% 0%, 90% 100%, 10% 90%);
    }

    :global(.Image.clipPath-7) :global(.Image-image) {
        -webkit-clip-path: polygon(5% 0%, 90% 10%, 90% 90%, 0% 100%);
                clip-path: polygon(5% 0%, 90% 10%, 90% 90%, 0% 100%);
    }

    :global(.Image.clipPath-8) :global(.Image-image) {
        -webkit-clip-path: polygon(0% 10%, 90% 0%, 100% 90%, 10% 100%);
                clip-path: polygon(0% 10%, 90% 0%, 100% 90%, 10% 100%);
    }

    :global(.Image.clipPath-9) :global(.Image-image) {
        -webkit-clip-path: polygon(10% 10%, 90% 0%, 100% 90%, 0% 100%);
                clip-path: polygon(10% 10%, 90% 0%, 100% 90%, 0% 100%);
    }

    :global(.Image.clipPath-10) :global(.Image-image) {
        -webkit-clip-path: polygon(10% 10%, 100% 0%, 100% 100%, 0% 90%);
                clip-path: polygon(10% 10%, 100% 0%, 100% 100%, 0% 90%);
    }</style>

<figure
    class={classNames('Image', className, {
        'layout-center': align == 'center',
        [`Image--withDimension dimension-${dimension}`]: dimension,
        [`Image--withDimension ${Object.keys(dimensions || {})
            .map(
                prefix =>
                    `${prefix ? `${prefix}-` : ''}dimension-${
                        dimensions[prefix]
                    }`
            )
            .join(' ')}`]: dimensions,
        'Image--cover': cover,
        'Image--contain': contain,
        'Image--rounded': rounded,
        'Image--circle': circle,
        'Image--shadow': shadow,
        [`Image--withPattern clipPath-${Math.round(
            Math.random() * 10
        )}`]: withPattern
    })}>
    {#if withPattern}
        <Loader randomize layers={1} class="Image-pattern" {index} />
    {/if}
    <img
        use:lazyLoad
        class={classNames('Image-image', {
            'layout-center': align == 'center'
        })}
        {...attrs}
        alt={alt || ''} />

    {#if caption}
        <caption class="Image-caption layout-center">{caption}</caption>
    {/if}
</figure>
