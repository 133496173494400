<script>
    import Card from 'components/Card'
    import formatDate from 'utils/dateFormatter.js'
    export let imageRight
    export let post
    export let index
    export let hideExcerpt = false
    export let hidePublished = false
    export let withImagePattern = true
    export let imageDirection = true

    let { image, image_alt, excerpt } = post.content
</script>

<style global>
    :global(.Blog-card) {
        margin-bottom: calc(var(--layout-gutter));
    }

    :global(.Blog-card) :global(.Image-image) {
        width: 100%;
        -o-object-fit: cover;
           object-fit: cover;
        max-height: 400px;
    }

    :global(.BlogCard-header) {
        display: block;
        margin-bottom: var(--layout-gutter);
    }

    :global(.BlogCard-pubdate) {
        font-weight: var(--font-weight-light);
        opacity: 0.5;
    }</style>

<Card
    class="Blog-card"
    {imageRight}
    imageLeft={!imageRight}
    to={post.full_slug}
    title={post.name}
    image={image && { src: image, alt: image_alt || post.name, width: 600, height: 600, index }}
    withPattern={withImagePattern}
    {imageDirection}>
    <div class="BlogCard-header">
        {#if !hidePublished}
            <time datetime={post.first_published_at} class="BlogCard-pubdate">
                {formatDate(new Date(post.first_published_at))}
            </time>
        {/if}
    </div>

    {#if excerpt && !hideExcerpt}{excerpt}{/if}
</Card>
